const colors = {
    primary: {
        light: '#00B0AE',
        main: '#00B0AE',
        dark: '#009A97',
        contrastText: '#FFF',
    },
    
    error: {
        light: '#ffa8a5',
        main: '#FB7676',
        dark: '#c4454a',
        contrastText: '#FFF',
    },

    commons: {
        padrao: "#219A97",
        fontColor: '#333333',
        gray: '#E5E5E5',
        darkGray: '#666666',
        gray2: '#F2F2F2',
        gray3: '#969696',
        gray4: '#E5E5E3',
        gray5: '#707070',
        gray6: '#BDBDBD',
        gray7: '#868686',
        gray8: '#E0E0E0',
        gray9: '#505050',
        gray10: '#707C97',
        gray10Dark: '#485166',
        gray11: '#F2F2F4',
        grayLight: '#F9F9F9',
        blue: '#52D5FC',
        yellow: '#FBBF6D',
        yellow2: '#F9BE73',
        darkYellow: '#E3922B',
        red: '#FB7676',
        darkRed: '#e27575',
        waikawaGrey: '#657496',
        brown: '#BC9292',
        purple: '#9B69FF',
        purpleDark: '#8868d9',
        black: '#0B0E19',
        green: "#00B0AE",
        white: '#FFFFFF',
        placeholderColor: '#666666',
        redLight: '#FFE1E0',
        blueLight: '#E3F4FE',
        greenLight: '#D4EEEE',
        yellowLight: '#FEF2E3',
        purpleLight: '#EAE3FE',
        waikawaGreyLight: '#E7E7E7',
        secondary: '#26ACA9',
        pink: '#FC71B4',
        pinkLight: '#FEF0F7',
    },

    status: {
        confirmado: "#00B0AE",
        aguardando: "#FBBF6D",
        cancelado: "#FB6D6D",
        atendido:  "#9B69FF",
        agendado: "#6DC7FB",
        bloqueado: "#666666",
        atendendo: "#657496",
        faltou: "#FC71B4",
        excluido:"#000"
    },

    statusLight: {
        confirmado: "#D4EEEE",
        aguardando: "#FEF2E3",
        cancelado: "#FFE1E0",
        atendido:  "#EAE3FE",
        agendado: "#E3F4FE",
        bloqueado: "#E7E7E7",
        atendendo: "#E7E7E7",
        faltou: "#FEF0F7",
        excluido:"#E5E5E3"
    },

    statusImc: {
        abaixoPesoII: '#739AF9',
        abaixoPeso: '#73C8F9',
        pesoNormal: '#27B04D',
        acimaPeso: '#FFE71F',
        obesidadeI: '#EB9217',
        obesidadeII: '#FE571D',
        obesidadeIII: '#E71919',
    },

    contingencia: {
        background: '#505050',
    },
};


export default colors;
