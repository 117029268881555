import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { inject } from 'mobx-react'

import PageTitle from '../../components/PageTitle/PageTitle'
import { Card } from './components'

import { AuthApi } from '../../config/api'
import { createMutation } from '../Querys'

import localStorageService, { USUARIO_LOGADO_KEY, REDES_KEY, CURRENT_AGENDA_OWNER } from '../../services/storage'
import { findUnidadeLogada } from '../../services/UnidadeService'

import selectRedeStyle from '../../assets/jss/pages/selectRedeStyle'
import { LogoTipo } from '../../assets/img/svg'
import { getColumns } from './utils/columns'
import BannerRedes from '../../assets/img/banner-redes.png'

@inject('filtroHeaderStore', 'unidadeStore', 'usuarioStore', 'chatStore', 'socketStore')
class SelectRede extends React.Component {
  state = {
    redes: [],
    loadingButton: { id: null, loading: false },
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.redes) {
      this.setState({ redes: this.props.location.state.redes })
    } else {
      this.props.history.push('/login')
    }
  }

  async handleClick(item) {
    const { usuarioStore, unidadeStore, history, filtroHeaderStore } = this.props
    AuthApi.post(
      'graphql',
      JSON.stringify({
        query: createMutation(
          {
            name: `alterarUnidadeAtual`,
            objType: 'Long',
            objName: 'idUnidade',
            attrsReturn: `
            id
            nome
            login
            fotoPerfil
            authorities {
              authority
            }
          `,
          },
          'mutation'
        ),
        variables: { idUnidade: item.id },
      })
    ).then(async response => {
      this.setState({ loadingButton: { id: item.id, loading: true } })
      localStorageService.set(USUARIO_LOGADO_KEY, response.data.data.alterarUnidadeAtual)
      localStorageService.set(REDES_KEY, this.state.redes)
      localStorageService.set(CURRENT_AGENDA_OWNER, null)

      await findUnidadeLogada()
      await usuarioStore.findProfissionalSaudeLogado()
      await unidadeStore.checkUsoTelefoneInternacional()
      await this.setupSocketConnections();
      usuarioStore.isAuthenticated = true;

      history.push('/')
      filtroHeaderStore.unidade = 0
      filtroHeaderStore.profissionalSaude = 0
      this.setState({ loadingButton: { id: item.id, loading: false } })
    })
  }

  setupSocketConnections = async () => {
    const { socketStore, chatStore } = this.props;
    await socketStore.connect(true)
    chatStore.enableChatListenners()
    await chatStore.getProfissionalSaudeAtual();
  }

  render() {
    const { classes } = this.props
    const { redes } = this.state

    return (
      <div className={classes.main}>
        <PageTitle title='Unidade' />
        <div className={classes.header}>
          <img src={LogoTipo} alt={'logo'} />
        </div>
        <div className={classes.content}>
          <aside className={classes.aside}>
            <img src={BannerRedes} alt={'Banner Redes Health'} />
          </aside>
          <div className={classes.container}>
            <h1 className={classes.title}>Selecione uma unidade:</h1>
            <div className={classes.wrapperRedes}>
              {redes.map((rede, index) => (
                <Card
                  key={index}
                  columns={getColumns({
                    nameRede: rede.nome,
                    handleClick: this.handleClick.bind(this),
                    loadingButton: this.state.loadingButton,
                  })}
                  dados={rede}
                />
              ))}
            </div>
          </div>
        </div> 
        <div className={classes.footer}> Ambiente para consultas </div>
      </div>
    )
  }
}

export default withStyles(selectRedeStyle)(SelectRede)
