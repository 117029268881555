import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({
  indicatorsContainer: {
    position: 'absolute',
    background: 'transparent',
    display: 'flex',
    gap: '8px',
    zIndex: '20',
    bottom: '80px',
    right: '50%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  indicator: {
    background: '#BDBDBD',
    height: '12px',
    width: '12px',
    borderRadius: '50%'
  },
  selected: {
    background: 'white'
  }
});

const Indicators = ({ classes, size, currentIndex, onClickIndicator }) => {
    
  const renderIndicators = () => {
    const indicators = [];

    for (let i = 0; i < size; i++) {
      indicators.push(
        <div 
          className={classNames(classes.indicator, i === currentIndex ? classes.selected : null)} 
          onClick={() => onClickIndicator(i)}
        />
      );
    }
    return indicators;
  }

  return (
    <div className={classes.indicatorsContainer}>
      {renderIndicators()}
    </div>
  )
}

export default withStyles(styles)(Indicators);
